<template>
  <div class="prices p-3">
    <div class="container border-bottom border-3">
      <div class="row">
        <h2 class="align-middle text-center chapter_title">TOKEN PRICE</h2>
        <div class="col-sm-12 col-lg-6">
          <div class="row mb-08">
            <div class="d-flex">
              <div class="me-auto p-2 flex-grow-1 align-self-center coin">
                <img src="@/assets/sol.png" class="coin-img"> SOL
              </div>
              <div class="p-2">
                <span class="token-price">{{ solana }}</span><span class="doll">USD</span>
              </div>
            </div>
            <div class="d-flex">
              <div class="me-auto p-2 flex-grow-1 align-self-center coin">
                <img src="@/assets/gst.svg" class="coin-img"> sGST
              </div>
              <div class="p-2">
                <span class="token-price">{{ gst }}</span><span class="doll">USD</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6">
          <div class="row mb-08">
            <div class="d-flex">
              <div class="me-auto p-2 flex-grow-1 align-self-center coin">
                <img src="@/assets/bnb.svg" class="coin-img"> BNB
              </div>
              <div class="p-2">
                <span class="token-price">{{ bnb }}</span><span class="doll">USD</span>
              </div>
            </div>
            <div class="d-flex">
              <div class="me-auto p-2 flex-grow-1 align-self-center coin">
                <img src="@/assets/gst.svg" class="coin-img"> bGST
              </div>
              <div class="p-2">
                <span class="token-price">{{ gst_bsc }}</span><span class="doll">USD</span>
              </div>
            </div>
            <div class="d-flex">
              <div class="me-auto p-2 flex-grow-1 align-self-center coin">
                <img src="@/assets/gmt.svg" class="coin-img"> GMT
              </div>
              <div class="p-2">
                <span class="token-price">{{ gmt }}</span><span class="doll">USD</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="select-chain p-3">
    <div class="container border-bottom border-3">
      <div class="row">
        <h2 class="align-middle chapter_title">CHAIN</h2>
        <div class="col text-center pb-3">
          <button class="chain align-middle text-center border" @click="chose_chain(chain)">{{ chain }}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="mint p-3">
    <div class="container border-bottom border-3">
      <h2 class="align-middle chapter_title">MINT</h2>
      <div class="row border-bottom">
        <div class="col p-2 d-flex justify-content-center">
          <span class="sneaker_no align-middle text-center">No.1</span>
        </div>
        <div class="col p-2 d-flex justify-content-center">
          <button class="rarity align-middle text-center border" v-bind:style="rare_bk_color_1"
                  @click="chose_sneaker(rarity_1, 1)">
            {{ rarity_1 }}
          </button>
          <button class="rarity align-middle text-center border" @click="plus_mint(mint_1, 1)">
            Mint {{ mint_1 }}/7
          </button>
        </div>
      </div>
      <div class="row border-bottom">
        <div class="col p-2 d-flex justify-content-center">
          <span class="sneaker_no align-middle text-center">No.2</span>
        </div>
        <div class="col p-2 d-flex justify-content-center">
          <button class="rarity align-middle text-center border" v-bind:style="rare_bk_color_2"
                  @click="chose_sneaker(rarity_2, 2)">
            {{ rarity_2 }}
          </button>
          <button class="rarity align-middle text-center border" @click="plus_mint(mint_2, 2)">
            Mint {{ mint_2 }}/7
          </button>
        </div>
      </div>
      <div class="row mint_cost border-bottom">
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">MINT COST</span>
        </div>
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">
            <img src="@/assets/gst.svg" class="coin-img"> <span class="token-price">{{
              total_mint_cost[0]
            }}</span> + <img src="@/assets/gmt.svg" class="coin-img"> <span class="token-price">{{
              total_mint_cost[1]
            }}</span> = <span class="token-price">{{ usd_mint_const }}</span><span class="doll">USD</span>
          </span>
        </div>
      </div>
      <div class="row mint_cost border-bottom">
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">SCROLL COST</span>
        </div>
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">
            <img src="@/assets/gmt.svg" class="coin-img"> <span class="token-price">
            {{ gmt_scroll_cost }} x 2
          </span> = <span class="token-price">{{ usd_scroll_cost }}</span><span class="doll">USD</span>
          </span>
        </div>
      </div>
      <div class="row mint_cost border-bottom">
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">Lv5 COST</span>
        </div>
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">
            <img src="@/assets/gst.svg" class="coin-img"> <span class="token-price">{{ gst_lv5_cost }}</span> + <img
              src="@/assets/gmt.svg" class="coin-img"> <span class="token-price">{{ gmt_lv5_cost }}</span> = <span
              class="token-price">{{ usd_lv5_cost }}</span><span class="doll">USD</span>
          </span>
        </div>
      </div>
      <div class="row mint_cost border-bottom">
        <span class="p-2 align-middle text-center">TOTAL COST</span>
        <span class="p-2 align-middle text-center">
          <span class="token-price">{{ usd_mint_const }}</span> + <span class="token-price">{{ usd_scroll_cost }}</span> + <span class="token-price">{{ usd_lv5_cost }}</span> = <span
            class="token-price">{{ usd_total_cost }}</span><span class="doll">USD</span>
        </span>
        <span class="p-2 align-middle text-center">
          = <img :src="chain_img" class="coin-img"> <span class="token-price">{{ chain_total_cost }}</span><span
            class="doll">{{ currency_total_cost }}</span>
        </span>
      </div>
    </div>
  </div>
  <div class="gem p-3">
    <div class="container border-bottom">
      <h2 class="align-middle chapter_title">GEM</h2>
      <div class="row border-bottom">
        <div class="col-lg p-2 d-brock align-middle text-center justify-content-center">
          <button class="select-gem border mt-4" @click="chose_gem_lv(gem_lv)">Level {{ gem_lv }} GEM</button>
        </div>
        <div class="col-lg p-2 d-flex justify-content-center">
          <img :src="gem_pic" class="gem-img">
        </div>
        <div class="col-lg p-2 align-middle text-center mt-lg-3">
          <p class="gem_effect">Attribute: +{{ gem_attribute }}
          <br>Effect: +{{ gem_effect }}% Base.</p>
        </div>
      </div>
      <div class="row gem_cost border-bottom">
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">UPGRADE COST</span>
        </div>
        <div class="col-lg p-2 d-flex justify-content-center">
          <div class="row">
            <div class="col-xs d-flex justify-content-center">
              <span class="align-middle text-center">
                <img src="@/assets/gst.svg" class="coin-img"> <span class="token-price">{{ gem_gst_cost }}</span> + <img src="@/assets/gmt.svg" class="coin-img"> <span class="token-price">{{ gem_gmt_cost }}</span> = <span class="token-price">{{ gem_upgrade_usd_cost }}</span><span class="doll">USD</span>
              </span>
            </div>
            <div class="col-xs d-flex justify-content-center">
              <span class="p-2 align-middle text-center">
          = <img :src="chain_img" class="coin-img"> <span class="token-price">{{ gem_upgrade_chain_cost }}</span><span
            class="doll">{{ currency_total_cost }}</span>
          </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row gem_cost border-bottom">
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">SUCCESS RATE</span>
        </div>
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">
            <p>{{ gem_success_rate }} %</p>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="mystery_box">
    <div class="container border-bottom">
      <h2 class="align-middle chapter_title">Mystery Box</h2>
      <div class="row border-bottom">
        <div class="col-lg p-2 d-brock align-middle text-center justify-content-center">
          <button class="select-gem border mt-4" @click="chose_mb_lv(mb_lv)">Level {{ mb_lv }} MB</button>
        </div>
        <div class="col-lg p-2 d-flex justify-content-center">
          <img :src="mb_pic" class="gem-img">
        </div>
      </div>
      <div class="row gem_cost border-bottom">
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">OPEN COST</span>
        </div>
        <div class="col-lg p-2 d-flex justify-content-center">
          <div class="row">
            <div class="col-xs d-flex justify-content-center">
              <span class="align-middle text-center">
                <img src="@/assets/gst.svg" class="coin-img"> <span class="token-price">{{ mb_gst_cost }}</span> + <img src="@/assets/gmt.svg" class="coin-img"> <span class="token-price">{{ mb_gmt_cost }}</span> = <span class="token-price">{{ mb_open_usd_cost }}</span><span class="doll">USD</span>
              </span>
            </div>
            <div class="col-xs d-flex justify-content-center">
              <span class="p-2 align-middle text-center">
          = <img :src="chain_img" class="coin-img"> <span class="token-price">{{ mb_open_chain_cost }}</span><span
            class="doll">{{ currency_total_cost }}</span>
          </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row gem_cost border-bottom">
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">UNLOCKING TAKES TIME</span>
        </div>
        <div class="col-lg p-2 d-flex justify-content-center">
          <span class="align-middle text-center">
            <p>{{ mb_open_time }} H</p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Mint-Price',
  data() {
    return {
      gst: "4.56",
      gst_bsc: "22.00",
      gmt: "2.59",
      solana: "111.22",
      bnb: "213.0",
      gst_14: 9999,
      gst_bsc_14: 9999,
      chain: 'Solana',
      chain_img: require(`@/assets/sol.png`),
      rarity_1: 'common',
      rarity_2: 'common',
      mint_1: 0,
      mint_2: 0,
      counter: 0,
      rare_bk_color_1: {
        background: "#babcbe"
      },
      rare_bk_color_2: {
        background: "#babcbe"
      },
      gst_mint_cost: [0, 0],
      gmt_mint_cost: [0, 0],
      total_mint_cost: ["?", "?"],
      usd_mint_const: 0,

      gmt_scroll_cost: 15,
      usd_scroll_cost: 20,

      gst_lv5_cost: 20,
      gmt_lv5_cost: 10,
      usd_lv5_cost: 0,

      usd_total_cost: 0,
      chain_total_cost: 0,
      sol_total_cost: 0,
      bnb_total_cost: 0,
      currency_total_cost: "SOL",

      gst_mint_table: {
        'common': [180, 180, 270, 360, 450, 540, 630],
        'uncommon': [680, 680, 1020, 1360, 1700, 2040, 2380],
        // 'rare': [2000, 2000, 3000, 4000, 5000, 6000, 7000],
        // 'epic': [8000, 8000, 12000, 16000, 20000, 24000, 28000],
      },
      gmt_mint_table: {
        'common': [20, 20, 30, 40, 50, 60, 70],
        'uncommon': [120, 120, 180, 240, 300, 360, 420],
        // 'rare': [0.8, 0.64, 0.48, 0.40, 0.32, 0.16],
        // 'epic': [0.65, 0.52, 0.39, 0.325, 0.26, 0.13]
      },
      gmt_mint_add_cost: {
        'common': [0, 200, 400, 800, 1600, 3200, 6400, 12800, 25600]
      },

      // GEM
      gem_lv: 1,
      gem_pic: require(`@/assets/gem_efficiency_lv1.jpg`),
      gem_upgrade_table: {
        'gst': [50, 100, 200, 300, -1, -1, -1, -1],
        'gmt': [0, 0, 0, 200, -1, -1, -1, -1],
        'attribute': [2, 8, 25, 72, 200, -1, -1, -1, -1],
        'effect': [5, 70, 220, 600, 1400, -1, -1, -1, -1],
        'success_rate': [0.35, 0.55, 0.65, 0.75, 0.85, 1, 1, 1],
      },
      gem_gst_cost: 50,
      gem_gmt_cost: 0,
      gem_upgrade_usd_cost: 0,
      gem_upgrade_chain_cost: 0,
      gem_attribute: 0,
      gem_effect: 0,
      gem_success_rate: 0,

      // Mystery Box
      mb_lv: 1,
      mb_pic: require(`@/assets/mystery_box_lv1.png`),
      mb_open_table: {
        'gst': [5, 7, 9, 16, 25, 36, 49, 64, 81, 100],
        'gmt': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'time': [48, 72, 96, 144, 168, 240, 288, 366, 384, 432],
      },
      mb_gst_cost: 9,
      mb_gmt_cost: 0,
      mb_open_time: 0,
      mb_open_usd_cost: 0,
      mb_open_chain_cost: 0,
    }
  },
  created: function () {
    fetch('https://api.coingecko.com/api/v3/coins/green-satoshi-token/market_chart?vs_currency=usd&days=1&interval=hourly').then((response) => {
      return response.json()
    }).then((result) => {
      const now = new Date;
      const yesterday_14 = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() - 1, 14));
      let unix_14 = Math.floor(yesterday_14.getTime());
      const today_14 = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 14));
      if (today_14.getTime() < Date.now()) {
        unix_14 = Math.floor(today_14.getTime());
      }
      const prices = result['prices']
      const price_14 = prices.find((p) => p[0] > unix_14);
      this.gst_14 = price_14[1]
    }).catch((e) => {
      console.log(e)  //エラーをキャッチし表示
    });

    fetch('https://api.coingecko.com/api/v3/coins/green-satoshi-token-bsc/market_chart?vs_currency=usd&days=1&interval=hourly').then((response) => {
      return response.json()
    }).then((result) => {
      const now = new Date;
      const yesterday_14 = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() - 1, 14));
      let unix_14 = Math.floor(yesterday_14.getTime());
      const today_14 = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 14));
      if (today_14.getTime() < Date.now()) {
        unix_14 = Math.floor(today_14.getTime());
      }
      const prices = result['prices']
      const price_14 = prices.find((p) => p[0] > unix_14);
      this.gst_bsc_14 = price_14[1]
    }).catch((e) => {
      console.log(e)  //エラーをキャッチし表示
    });
  },
  mounted: function () {
    axios.get('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd')
        .then(function (response) {
          this.solana = response.data['solana']['usd'].toFixed(2);
        }.bind(this))
    axios.get('https://api.coingecko.com/api/v3/simple/price?ids=green-satoshi-token&vs_currencies=usd')
        .then(function (response) {
          this.gst = response.data['green-satoshi-token']['usd'].toFixed(2);
        }.bind(this))
    axios.get('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd')
        .then(function (response) {
          this.bnb = response.data['binancecoin']['usd'].toFixed(2);
        }.bind(this))
    axios.get('https://api.coingecko.com/api/v3/simple/price?ids=green-satoshi-token-bsc&vs_currencies=usd')
        .then(function (response) {
          this.gst_bsc = response.data['green-satoshi-token-bsc']['usd'].toFixed(2);
        }.bind(this))
    axios.get('https://api.coingecko.com/api/v3/simple/price?ids=STEPN&vs_currencies=usd')
        .then(function (response) {
          this.gmt = response.data['stepn']['usd'].toFixed(2);
        }.bind(this))
  },
  methods: {
    plus_mint: function (mint, num) {
      if (num === 1) {
        if (mint === 7) {
          this.mint_1 = 0
        } else {
          this.mint_1 += 1
        }
      }
      if (num === 2) {
        if (mint === 7) {
          this.mint_2 = 0
        } else {
          this.mint_2 += 1
        }
      }
    },
    chose_chain: function (chain) {
      if (chain === "Solana") {
        this.chain = "BNB Smart Chain"
        this.chain_img = require(`@/assets/bnb.svg`)
      }
      if (chain === "BNB Smart Chain") {
        this.chain = "Solana"
        this.chain_img = require(`@/assets/sol.png`)
      }
    },
    chose_sneaker: function (rarity, num) {
      let rare;
      let bk_color;
      if (rarity === "common") {
        rare = "uncommon"
        bk_color = "#aed144"
      }
      if (rarity === "uncommon") {
        rare = "rare"
        bk_color = "#47aced"
      }
      if (rarity === "rare") {
        rare = "epic"
        bk_color = "#a398eb"
      }
      if (rarity === "epic") {
        rare = "legendary"
        bk_color = "#f5a836"
      }
      if (rarity === "legendary") {
        rare = "common"
        bk_color = "#babcbe"
      }

      if (num === 1) {
        this.rarity_1 = rare
        this.rare_bk_color_1.background = bk_color
      } else {
        this.rarity_2 = rare
        this.rare_bk_color_2.background = bk_color
      }
    },
    get_gmt_add_cost: function (rarity, price) {
      let rate_class = 0
      // 割合の算出
      if (price < 4) {
        rate_class = 0
      } else if (4 <= price && price < 8) {
        rate_class = 1
      } else if (8 <= price && price < 12) {
        rate_class = 2
      } else if (12 <= price && price < 16) {
        rate_class = 3
      } else if (16 <= price && price < 20) {
        rate_class = 4
      } else if (20 <= price && price < 30) {
        rate_class = 5
      } else if (30 <= price && price < 40) {
        rate_class = 6
      } else if (40 <= price && price < 50) {
        rate_class = 7
      } else if (50 <= price) {
        rate_class = 8
      }
      return this.gmt_mint_add_cost[rarity][rate_class]
    },
    chose_gem_lv: function (gem_lv) {
      // レベル
      if (gem_lv === 9) {
        this.gem_lv = 1
      } else {
        this.gem_lv += 1
      }
      // 画像
      if (this.gem_lv <= 4) {
        this.gem_pic = require(`@/assets/gem_efficiency_lv${this.gem_lv}.jpg`)
      } else {
        this.gem_pic = require(`@/assets/etherock.png`)
      }
    },
    chose_mb_lv: function (mb_lv) {
      // レベル
      if (mb_lv === 10) {
        this.mb_lv = 1
      } else {
        this.mb_lv += 1
      }
      // 画像
      this.mb_pic = require(`@/assets/mystery_box_lv${this.mb_lv}.png`)
    }
  },
  computed: {
    mint_var() {
      // watchで複数の値を監視するために設定
      return [this.rarity_1, this.mint_1, this.rarity_2, this.mint_2, this.chain, this.gst_14]
    },
    gem_var() {
      return [this.chain, this.mint_var, this.gem_lv]
    },
    mb_var() {
      return [this.chain, this.mint_var, this.mb_lv]
    }
  },
  watch: {
    mint_var: {
      handler: function () {
        // 選択できるレアリティ
        let ok_rare = ['common']
        // 基準価格の取得
        let border_price = this.gst_14
        let gst_price = this.gst
        let gmt_price = this.gmt
        if (this.chain === "BNB Smart Chain") {
          border_price = this.gst_bsc_14
          gst_price = this.gst_bsc
        }

        // スクロールのコスト
        this.usd_scroll_cost = this.gmt_scroll_cost * 2 * gmt_price
        // Lv5までにかかるコスト
        this.usd_lv5_cost = this.gst_lv5_cost * gst_price + this.gmt_lv5_cost * gmt_price

        // No.1
        if (ok_rare.includes(this.rarity_1)) {
          this.gst_mint_cost[0] = this.gst_mint_table[this.rarity_1][this.mint_1]
          this.gmt_mint_cost[0] = this.gmt_mint_table[this.rarity_1][this.mint_1] + this.get_gmt_add_cost(this.rarity_1, border_price)
        }
        // No.2
        if (ok_rare.includes(this.rarity_2)) {
          this.gst_mint_cost[1] = this.gst_mint_table[this.rarity_2][this.mint_2]
          this.gmt_mint_cost[1] = this.gmt_mint_table[this.rarity_2][this.mint_2] + this.get_gmt_add_cost(this.rarity_2, border_price)
        }

        if (ok_rare.includes(this.rarity_1) && ok_rare.includes(this.rarity_2)) {
          this.total_mint_cost = [this.gst_mint_cost[0] + this.gst_mint_cost[1], this.gmt_mint_cost[0] + this.gmt_mint_cost[1]]
          this.usd_mint_const = this.total_mint_cost[0] * gst_price + this.total_mint_cost[1] * gmt_price
          this.usd_total_cost = this.usd_mint_const + this.usd_scroll_cost + this.usd_lv5_cost
          this.sol_total_cost = this.usd_total_cost / this.solana
          this.bnb_total_cost = this.usd_total_cost / this.bnb

          this.usd_mint_const = this.usd_mint_const.toFixed(1)
          this.usd_total_cost = (parseFloat(this.usd_mint_const) + parseFloat(this.usd_scroll_cost) + parseFloat(this.usd_lv5_cost)).toFixed(1)
          this.sol_total_cost = this.sol_total_cost.toFixed(2)
          this.bnb_total_cost = this.bnb_total_cost.toFixed(2)
          if (this.chain === "Solana") {
            this.chain_total_cost = this.sol_total_cost
            this.currency_total_cost = "SOL"
          }
          if (this.chain === "BNB Smart Chain") {
            this.chain_total_cost = this.bnb_total_cost
            this.currency_total_cost = "BNB"
          }
        } else {
          // 該当レア以外
          this.total_mint_cost = ["?", "?"]
          this.usd_mint_const = "?"
          this.usd_total_cost = "?"
          this.sol_total_cost = "?"
          this.bnb_total_cost = "?"
          this.chain_total_cost = "?"
        }

        if (this.mint_1 === 7 || this.mint_2 === 7) {
          this.total_mint_cost = ["😇", "😱"]
          this.usd_mint_const = "😛"
          this.usd_total_cost = "😝"
          this.sol_total_cost = "😎"
          this.bnb_total_cost = "😎"
          this.chain_total_cost = "😎"
        }
        this.usd_scroll_cost = this.usd_scroll_cost.toFixed(1)
        this.usd_lv5_cost = this.usd_lv5_cost.toFixed(1)
      },
    },
    gem_var: {
      handler: function () {
        // 価格の取得
        let gst_price = this.gst
        let gmt_price = this.gmt
        if (this.chain === "BNB Smart Chain") {
          gst_price = this.gst_bsc
        }

        // アップグレードコスト
        if (this.gem_lv <= 4) {
          this.gem_gst_cost = this.gem_upgrade_table['gst'][this.gem_lv - 1]
          this.gem_gmt_cost = this.gem_upgrade_table['gmt'][this.gem_lv - 1]
          this.gem_upgrade_usd_cost = this.gem_gst_cost * gst_price + this.gem_gmt_cost * gmt_price

          this.upgrade_sol_cost = this.gem_upgrade_usd_cost / this.solana
          this.upgrade_bnb_cost = this.gem_upgrade_usd_cost / this.bnb

          this.gem_attribute = this.gem_upgrade_table['attribute'][this.gem_lv - 1]
          this.gem_effect = this.gem_upgrade_table['effect'][this.gem_lv - 1]
          this.gem_success_rate = this.gem_upgrade_table['success_rate'][this.gem_lv - 1] * 100

          this.gem_upgrade_usd_cost = this.gem_upgrade_usd_cost.toFixed(1)
          this.upgrade_sol_cost = this.upgrade_sol_cost.toFixed(2)
          this.upgrade_bnb_cost = this.upgrade_bnb_cost.toFixed(2)
          this.gem_success_rate = this.gem_success_rate.toFixed(0)

        } else {
          this.upgrade_sol_cost = "?"
          this.upgrade_bnb_cost = "?"
          this.gem_gst_cost = "?"
          this.gem_gmt_cost = "?"
          this.gem_upgrade_usd_cost = "?"
          this.gem_upgrade_chain_cost = "?"
          this.gem_attribute = "?"
          this.gem_effect = "?"
          this.gem_success_rate = "?"
        }

        if (this.chain === "Solana") {
              this.gem_upgrade_chain_cost = this.upgrade_sol_cost
            }
        if (this.chain === "BNB Smart Chain") {
          this.gem_upgrade_chain_cost = this.upgrade_bnb_cost
        }
        console.log("gem_var")

      }
    },
    mb_var: {
      handler: function () {
        // 価格の取得
        let gst_price = this.gst
        let gmt_price = this.gmt
        if (this.chain === "BNB Smart Chain") {
          gst_price = this.gst_bsc
        }
        // 開封コスト
        this.mb_gst_cost = this.mb_open_table['gst'][this.mb_lv - 1]
        this.mb_gmt_cost = this.mb_open_table['gmt'][this.mb_lv - 1]
        this.mb_open_usd_cost = this.mb_gst_cost * gst_price + this.mb_gmt_cost * gmt_price

        this.mb_sol_cost = this.mb_open_usd_cost / this.solana
        this.mb_bnb_cost = this.mb_open_usd_cost / this.bnb

        this.mb_open_usd_cost = this.mb_open_usd_cost.toFixed(1)
        this.mb_sol_cost = this.mb_sol_cost.toFixed(2)
        this.mb_bnb_cost = this.mb_bnb_cost.toFixed(2)

        if (this.chain === "Solana") {
              this.mb_open_chain_cost = this.mb_sol_cost
            }
        if (this.chain === "BNB Smart Chain") {
          this.mb_open_chain_cost = this.mb_bnb_cost
        }

        // 開封時間
        this.mb_open_time = this.mb_open_table['time'][this.mb_lv - 1]

        console.log("mb_var")
      }
    },
  },
}
</script>

<style scoped>

.prices {
  font-family: tt-octosquares-w05-black;
  font-style: normal;
}

h2.align-middle {
  text-align: center;
  font-size: 34px;
}

.coin {
  font-size: 26px;
}

.token-price {
  font-size: 28px;
}

.doll {
  font-size: 22px;
  padding-left: 10px;
}

.select-chain {
  font-family: tt-octosquares-w05-black;
  font-style: normal;
}

.mint {
  font-family: tt-octosquares-w05-black;
  font-style: normal;
}

.gem {
  font-family: tt-octosquares-w05-black;
  font-style: normal;
}

.mystery_box {
  font-family: tt-octosquares-w05-black;
  font-style: normal;
}

.chain {
  color: black;
  text-decoration: none;
  font-size: 30px;
  padding: 2px 10px;
  cursor: pointer;
}

.sneaker_no {
  width: 200px;
  font-size: 25px;
  padding: 5px;
  text-transform: uppercase;
}

.rarity {
  color: black;
  text-decoration: none;
  width: 200px;
  font-size: 22px;
  padding: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

.mint_cost {
  font-size: 25px;
  padding: 5px;
}

.select-gem {
  color: black;
  text-decoration: none;
  font-size: 30px;
  padding: 2px 10px;
  cursor: pointer;
}

.gem-img {
  height: 100px;
}

.gem_cost {
  font-size: 25px;
  padding: 5px;
}

.gem_effect {
  font-size: 20px;
}

</style>