<template>
  <header>
    <div class="container">
      <div class="row logo">
        <div class="col-auto me-auto">
          <span class="align-middle">STEPN.DAY</span>
          <span class="align-middle">
            <img class="stepn-logo" src="@/assets/logo.svg" alt="stepn">
          </span>
        </div>
      </div>
    </div>
  </header>
  <Mint/>
  <footer class="footer-str">
    <div class="container">
      <div class="row p-3">
        <div class="col-sm">
          <span class="justify-content-center">
            <p>Ver 0.0.7</p>
            <p>Disclaimer: based on <span class="unofficial">unofficial</span> STEPN Discord community. Real value may be different.</p>
          </span>
        </div>
        <div class="col-sm">
          <span class="d-flex justify-content-center">
            <a class="official-link p-2 text-center" target="_blank" href="https://linktr.ee/stepnofficial">Official STEPN Resources</a>
          </span>
          <span class="d-flex p-2 justify-content-center">
            <Popper content="Wallet address copied! 😍">
              <button class="official-link p-2 text-center" @click="writeToClipboard(my_text)">Support Project
                <img src="@/assets/sol.png" class="coin-img" alt="sol">
                <img src="@/assets/gst.svg" class="coin-img" alt="gst">
                <img src="@/assets/gmt.svg" class="coin-img" alt="gmt">
                <img src="@/assets/usdc.svg" class="coin-img" alt="usdc">
              </button>
            </Popper>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
@import url("fonts/font.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

html {
  touch-action: manipulation;
}

.logo {
  font-family: tt-octosquares-w05-black;
  font-style: normal;
  font-size: 40px;
  padding: 10px;
}

.stepn-logo {
  height: 40px
}

.footer-str {
  font-family: 'Roboto Mono', monospace;
}

.official-link {
  color: black;
  text-decoration: none;
  border: dashed;
  width: 342px;
  background: white;
}

.unofficial {
  color: red;
}

.coin-img {
  height: 27px;
}

:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #000000;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 32px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>

<script>
import Mint from './components/Mint'
import Popper from "vue3-popper";


export default {
  name: 'App',
  components: {
    Mint,
    Popper,
  },
  mounted() {
    const title = "STEPN.DAY"
    const description = "Cost calculator in STEPN."
    const keywords = "STEPN calculator, STEPN DAY, MINT"
    document.title = title
    document.querySelector("meta[name='description']").setAttribute('content', description)
    document.querySelector("meta[name='keywords']").setAttribute('content', keywords)
  },
  data() {
    return {
      my_text: 'DRpGwvZr8VLKeMHTmYk9GVZQNvjADy3EEeyvAHyfF2AA'
    }
  },
  methods: {
    writeToClipboard(text) {
      navigator.clipboard.writeText(text).catch((e) => {
        console.error(e)
      })
    }
  }
}
</script>
